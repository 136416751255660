// src/components/ErrorBoundary.js
import React from 'react';
import { postToFrontEndErrors } from '../services/actionsService';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            hasError: false,
            shouldShowError: false 
        };
        this.managementTips = [
            "Remember to celebrate small wins with your team",
            "Clear communication prevents most workplace issues",
            "Take time to listen to your team's concerns",
            "Regular feedback helps everyone grow",
            "A motivated team is a productive team",
            "Lead by example, not just words",
            "Recognition goes a long way in building morale"
        ];
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render shows the fallback UI.
        return { hasError: true, shouldShowError: false };
    }

    componentDidMount() {
        // Record the time when the page successfully loads
        localStorage.setItem('lastPageLoadTime', Date.now().toString());
        // Clear the refresh attempt flag when component mounts successfully
        localStorage.removeItem('errorBoundaryRefreshAttempt');
    }

    componentDidCatch(error, errorInfo) {
        // You can log the error to an error reporting service
        console.error('ErrorBoundary caught an error', error, errorInfo);
        postToFrontEndErrors({
            error_name: error.name,
            message: error.message,
            error: errorInfo
        });
        
        const hasAttemptedRefresh = localStorage.getItem('errorBoundaryRefreshAttempt');
        const lastPageLoadTime = localStorage.getItem('lastPageLoadTime');
        const currentTime = Date.now();
        const ONE_MINUTE = 60000; // 60,000 milliseconds = 1 minute

        if (!hasAttemptedRefresh && (!lastPageLoadTime || (currentTime - parseInt(lastPageLoadTime)) > ONE_MINUTE)) {
            localStorage.setItem('errorBoundaryRefreshAttempt', 'true');
            window.location.reload();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.hasError && !this.state.shouldShowError) {
            // Wait a brief moment to see if we'll refresh
            setTimeout(() => {
                this.setState({ shouldShowError: true });
            }, 100);
        }
    }

    render() {
        if (this.state.hasError && this.state.shouldShowError) {
            const randomTip = this.managementTips[Math.floor(Math.random() * this.managementTips.length)];
            return (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <h1>Something went wrong.</h1>
                    <p>Please reload the page or contact support.</p>
                    <p style={{ fontSize: '0.9em', color: '#222', marginTop: '36px' }}>
                        Management Tip: {randomTip}
                    </p>
                </div>
            );
        }

        return this.props.children; 
    }
}

export default ErrorBoundary;
