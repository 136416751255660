import axios from 'axios';
import { toast } from 'react-toastify';
import { API_SERVER } from '../config/constant';
import { SET_COURSE_DETAIL, SET_COURSE_DETAIL_ITEMS, SET_COURSE_PROGRESS } from '../store/actions';
import { buildFormData } from '../utils/common';
import { getCourseProgressionPercentage } from '../views/KnowledgeBase/helpers';
import http, { serializeQuery, showAxiosErrorNotification } from './httpService';

const getAssignedCourses = async () => {
    try {
        const res = await axios.get(`${API_SERVER}knowledgebase/api/courses-assigned-and-read/`, http.tokenConfig());
        return res.data;
    } catch (err) {
        console.error('An error occurred while fetching assigned courses >>>', err, err.response);
        return []; // Or you can throw the error here or return an object with some default values
    }
};

const getArticleTypes = async () => {
    try {
        const res = await axios.get(`${API_SERVER}knowledgebase/api/article-types/`, http.tokenConfig());
        return res.data;
    } catch (err) {
        console.error('An error occurred while fetching ArticleCategories >>>', err, err.response);
        return false;
    }
};

const createArticleType = async (data) => {
    try {
        const res = await axios.post(`${API_SERVER}knowledgebase/api/article-types/`, data, http.tokenConfig());
        toast.success('Article type Created successfully!');
        return res.data;
    } catch (err) {
        showAxiosErrorNotification(err?.response);
        console.error('An error occurred while creating question article >>>', err, err.response);
        return false;
    }
};

const updateArticleType = async (id, data) => {
    try {
        const res = await axios.patch(`${API_SERVER}knowledgebase/api/article-types/${id}/`, data, http.tokenConfig());
        toast.success('Article type updated successfully!');
        return res.data;
    } catch (err) {
        showAxiosErrorNotification(err?.response);
        console.error('An error occurred while creating question article >>>', err, err.response);
        return false;
    }
};

const deleteArticleType = async (id) => {
    try {
        const res = await axios.delete(`${API_SERVER}knowledgebase/api/article-types/${id}/`, http.tokenConfig());
        toast.success('Article Type Deleted Successfully!');
        return res.data;
    } catch (err) {
        showAxiosErrorNotification(err?.response);
        console.error('An error occurred while creating question article >>>', err, err.response);
        return false;
    }
};

const getArticleCategories = async (query) => {
    // pass query as a dict e.g. {simple_details: true}
    const queryParams = query ? serializeQuery(query) : '';
    try {
        const res = await axios.get(`${API_SERVER}knowledgebase/api/category/list-create/?${queryParams}`, http.tokenConfig());
        return res.data;
    } catch (err) {
        console.error('An error occurred while fetching ArticleCategories >>>', err, err.response);
        return []; // Or you can throw the error here or return an object with some default values
    }
};

const postArticleCategories = async (data) => {
    try {
        const res = await axios.post(`${API_SERVER}knowledgebase/api/category/list-create/`, data, http.tokenConfig());
        toast.success('Category Added');
        return res.data;
    } catch (err) {
        toast.error('An error occurred while creating Category ' + JSON.stringify(err.response.data));
        console.error('An error occurred while fetching ArticleCategories >>>', err, err.response);
        return []; // Or you can throw the error here or return an object with some default values
    }
};

const patchArticleCategories = async (id, data) => {
    try {
        const res = await axios.patch(`${API_SERVER}knowledgebase/api/category/list-edit/${id}/`, data, http.tokenConfig());
        toast.success('Category Updated');
        return res.data;
    } catch (err) {
        toast.error('An error occurred while updating Category ' + JSON.stringify(err.response.data));
        console.error('An error occurred while fetching ArticleCategories >>>', err, err.response);
        return []; // Or you can throw the error here or return an object with some default values
    }
};

const deleteArticleCategories = async (id) => {
    try {
        const res = await axios.delete(`${API_SERVER}knowledgebase/api/category/list-edit/${id}/`, http.tokenConfig());
        toast.success('Category Deleted Successfully!');
        return res.data;
    } catch (err) {
        toast.error('An error occurred while deleting Category ' + JSON.stringify(err.response.data));
        console.error('An error occurred while deleting ArticleCategories >>>', err, err.response);
        return []; // Or you can throw the error here or return an object with some default values
    }
};

const deleteArticle = async (id) => {
    try {
        const res = await axios.delete(`${API_SERVER}knowledgebase/api/retrieve-update-delete/${id}/`, http.tokenConfig());
        toast.warning('Article Deleted Successfully!');
        return true;
    } catch (err) {
        toast.error('An error occurred while deleting Article ' + JSON.stringify(err.response.data));
        console.error('An error occurred while deleting Article >>>', err, err.response);
        return false; // Or you can throw the error here or return an object with some default values
    }
};

const getArticle = async (id) => {
    try {
        const res = await axios.get(`${API_SERVER}knowledgebase/api/category/list-edit/${id}/`, http.tokenConfig());
        return res.data;
    } catch (err) {
        console.error('An error occurred while fetching the article >>>', err, err.response);
        return {}; // Or you can throw the error here or return an object with some default values
    }
};

const fetchArticle = async (id, returnError, query) => {
    const queryParams = query ? serializeQuery(query) : '';
    try {
        const res = await axios.get(`${API_SERVER}knowledgebase/api/retrieve-update-delete/${id}/?${queryParams}`, http.tokenConfig());
        console.log('Data fetched successfully', { data: res.data });
        return res.data;
    } catch (err) {
        console.error('An error occurred while fetching the article >>>', err, err.response);
        return returnError ? err : false; // Or you can throw the error here or return an object with some default values
    }
};

const postArticleVideo = async (data, articleId) => {
    const formData = new FormData();
    buildFormData(formData, data);

    try {
        const res = await axios.post(`${API_SERVER}knowledgebase/api/video-upload/${articleId}/`, formData, http.multiPartTokenConfig());
        return res.data;
    } catch (err) {
        console.log('An error occurred while creating the article >>>', err, err.response);
        return false; // Or you can throw the error here or return an object with some default values
    }
};

const postArticle = async (data) => {
    // formdata does not seem to be needed
    // const formData = new FormData();
    // buildFormData(formData, data);

    try {
        const res = await axios.post(`${API_SERVER}knowledgebase/api/create/`, data, http.tokenConfig());
        toast.success('Article Created Successfully!');
        return res.data;
    } catch (err) {
        toast.error('Article Error ' + JSON.stringify(err.response.data));
        console.log('An error occurred while creating the article >>>', err, err.response);
        return false; // Or you can throw the error here or return an object with some default values
    }
};

const commonPatchArticle = async (id, data, url) => {
    let video = data.video;

    if (video !== null || video !== '') {
        // we are passing video as a media file hence we have to submit it
        // using multipart/form-data in the postArticleVideo endpoint
        delete data['video'];
    }

    try {
        const res = await axios.patch(`${API_SERVER}${url}`, data, http.tokenConfig());

        if (video) {
            // we now post the video
            await postArticleVideo(video, id);
        }
        // console.log('Article updated', res.data);
        toast.success('Article Updated Successfully!');
        return res.data;
    } catch (err) {
        toast.error('Article Error ' + JSON.stringify(err?.response?.data));
        console.error('An error occurred while Updating the article >>>', err, err.response);
        return {}; // Or you can throw the error here or return an object with some default values
    }
};

const patchArticle = async (id, data) => {
    return await commonPatchArticle(id, data, `knowledgebase/api/retrieve-update-delete/${id}/`);
};

const updateDraftArticle = async (id, data) => {
    return await commonPatchArticle(id, data, `knowledgebase/api/draft-articles/${id}/`);
};

const createDraftArticle = async (data) => {
    const formData = new FormData();
    buildFormData(formData, data);

    try {
        const res = await axios.post(`${API_SERVER}knowledgebase/api/draft-article-create/`, formData, http.multiPartTokenConfig());

        if (data?.body) {
            toast.success('Draft Article Created Successfully!');
        }

        return res?.data;
    } catch (err) {
        toast.error('Draft Article Error ' + JSON.stringify(err.response.data));
        console.log('An error occurred while creating the draft article >>>', err, err.response);
        return false; // Or you can throw the error here or return an object with some default values
    }
};

const getDraftArticles = async (query) => {
    // pass query as a dict e.g. {page: 1}
    const queryParams = query ? serializeQuery(query) : '';
    try {
        const { data } = await http.get(`${API_SERVER}knowledgebase/api/draft-articles/?${queryParams}`, http.tokenConfig());
        return data;
    } catch (err) {
        console.error('An error occurred while fetching draft >>>', err, err.response);
        return false;
    }
};

const getDraftArticleDetails = async (id, returnError) => {
    try {
        const res = await axios.get(`${API_SERVER}knowledgebase/api/draft-articles/${id}/`, http.tokenConfig());
        return res.data;
    } catch (err) {
        console.error('An error occurred while fetching the article >>>', err, err.response);
        return returnError ? err : false; // Or you can throw the error here or return an object with some default values
    }
};

const deleteDraftArticle = async (id) => {
    try {
        await axios.delete(`${API_SERVER}knowledgebase/api/draft-articles/${id}/`, http.tokenConfig());
        toast.warning('Article Deleted Successfully!');
        return 'success';
    } catch (err) {
        toast.error('An error occurred while deleting draft Article ' + JSON.stringify(err.response.data));
        console.error('An error occurred while deleting draft Article >>>', err, err.response);
        return false; // Or you can throw the error here or return an object with some default values
    }
};

const postQuestionArticle = async (data) => {
    try {
        const res = await axios.post(`${API_SERVER}knowledgebase/api/questions/`, data, http.tokenConfig());
        toast.success('Question Created Successfully!');
        return res.data;
    } catch (err) {
        toast.error('Question Create Error ' + JSON.stringify(err.response.data));
        console.error('An error occurred while creating question article >>>', err, err.response);
        return false;
    }
};

const createCourse = async (data) => {
    try {
        const res = await axios.post(`${API_SERVER}knowledgebase/api/courses/`, data, http.tokenConfig());
        toast.success('Course created successfully!');
        return res.data;
    } catch (err) {
        // toast.error('Course Create Error ' + JSON.stringify(err.response.data));
        showAxiosErrorNotification(err?.response);
        return false;
    }
};

const patchCourse = async (id, data) => {
    try {
        const response = await axios.patch(`${API_SERVER}knowledgebase/api/courses/${id}/`, data, http.tokenConfig());
        if (response.status === 200) {
            toast.success('Course updated successfully!');
            // updateCourseDataToState(response.data)
            return response.data;
        } else {
            toast.error('Course Error');
            throw new Error('Failed to update course.');
        }
    } catch (error) {
        console.log(error);
        toast.error('Course Error ' + JSON.stringify(error.response.data));
        throw new Error('Failed to update course.');
    }
};

const getCourseCategories = async () => {
    try {
        const res = await axios.get(`${API_SERVER}knowledgebase/api/course-categories/`, http.tokenConfig());
        return res.data;
    } catch (err) {
        console.error('An error occurred while fetching categories >>>', err, err.response);
        return false; // Or you can throw the error here or return an object with some default values
    }
};

const addCourseCategory = async (data) => {
    try {
        const res = await axios.post(`${API_SERVER}knowledgebase/api/course-categories/`, data, http.tokenConfig());
        toast.success('Course category created successfully!');
        return res.data;
    } catch (err) {
        // toast.error('Course Create Error ' + JSON.stringify(err.response.data));
        showAxiosErrorNotification(err?.response);
        return false;
    }
};

const updateCourseCategory = async (id, data) => {
    try {
        const res = await axios.put(`${API_SERVER}knowledgebase/api/course-category/${id}/`, data, http.tokenConfig());
        toast.success('Category Updated Successfully!');
        return res.data;
    } catch (err) {
        showAxiosErrorNotification(err?.response);
        return false; // Or you can throw the error here or return an object with some default values
    }
};

const getCategoriesWithArticles = async (query) => {
    const queryParams = query ? serializeQuery(query) : '';
    try {
        const res = await axios.get(`${API_SERVER}knowledgebase/api/category-with-articles/?${queryParams}`, http.tokenConfig());
        return res.data;
    } catch (err) {
        console.error('An error occurred while fetching the article >>>', err, err.response);
        return false; // Or you can throw the error here or return an object with some default values
    }
};

export const getKBArticles = async (query) => {
    // pass query as a dict e.g. {simple_details: true}
    const queryParams = query ? serializeQuery(query) : '';
    try {
        const { data } = await http.get(`${API_SERVER}knowledgebase/api/list-create/?${queryParams}`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log('error fetching kb articles >>', error);
        return false;
    }
};

export const getKBArticlesSimple = async (query) => {
    // pass query as a dict e.g. {simple_details: true}
    const queryParams = query ? serializeQuery(query) : '';
    try {
        const { data } = await http.get(`${API_SERVER}knowledgebase/api/simple-list/?${queryParams}`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log('error fetching kb articles >>', error);
        return false;
    }
};

const getAcknowledgements = async (query) => {
    // pass query as a dict e.g. {simple_details: true}
    const queryParams = query ? serializeQuery(query) : '';
    try {
        const { data } = await http.get(`${API_SERVER}knowledgebase/api/acknowledgements/?${queryParams}`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log('error fetching kb articles >>', error);
        return false;
    }
};

const createKBAcknowledgement = async (data) => {
    try {
        const res = await axios.post(`${API_SERVER}knowledgebase/api/acknowledgements/`, data, http.tokenConfig());
        return res.data;
    } catch (err) {
        toast.error('Article Error ' + JSON.stringify(err.response.data));
        console.error('An error occurred while creating the article >>>', err, err.response);
        return false;
    }
};

const acknowledgeArticle = async (data, articleId) => {
    // data = {answer: 'your answer'}
    try {
        const res = await axios.post(`${API_SERVER}knowledgebase/api/mark-as-read/${articleId}/`, data, http.tokenConfig());
        toast.success('Article acknowledged successfully!');
        return res.data;
    } catch (err) {
        toast.error('An error occurred in acknowledging article, please try again' + JSON.stringify(err.response?.data));
        console.error('An error occurred in acknowledging article ', err, err.response);
    }
};

const markCourseAsReadForEmployee = async (data) => {
    try {
        const res = await axios.post(`${API_SERVER}knowledgebase/api/mark-course-as-read/`, data, http.tokenConfig());
        toast.success('Course acknowledged successfully!');
        return res.data;
    } catch (err) {
        toast.error('An error occurred in acknowledging the course, please try again' + JSON.stringify(err.response?.data));
        console.error('An error occurred in acknowledging the course ', err, err.response);
    }
};

const reassignCourse = async (courseId, data) => {
    try {
        const res = await axios.put(`${API_SERVER}knowledgebase/api/reassign-course/${courseId}/`, data, http.tokenConfig());
        toast.success('Course reassigned successfully!');
        return res.data;
    } catch (err) {
        toast.error('An error occurred in reassigning the course, please try again' + JSON.stringify(err.response?.data));
        console.error('An error occurred in reassigning the course ', err, err.response);
        return false;
    }
};

const getCourseAssignedUsers = async (courseId) => {
    try {
        const res = await axios.get(`${API_SERVER}knowledgebase/api/course-assigned-users/${courseId}/`, http.tokenConfig());

        return res.data;
    } catch (err) {
        console.error('error in fetching course users', err, err.response);
        return false;
    }
};

const fetchCourseDetails = async (courseId, dispatch) => {
    try {
        const res = await axios.get(`${API_SERVER}knowledgebase/api/courses/${courseId}/`, http.tokenConfig());
        dispatch({ type: SET_COURSE_DETAIL, payload: res.data });
        const sectionItems = [];
        res.data.sections?.map((section) => {
            sectionItems.push(...section.section_items);
        });
        dispatch({ type: SET_COURSE_DETAIL_ITEMS, payload: sectionItems });
        const progressPercentage = getCourseProgressionPercentage(sectionItems);
        dispatch({ type: SET_COURSE_PROGRESS, payload: progressPercentage });
        console.log('res data >>>', res.data);
        return res.data;
    } catch (err) {
        toast.error('An error occurred in fetching courses, please try again' + JSON.stringify(err.response?.data));
        console.error(err.response);
        return false;
    }

    // let courseDetail = null;
    // axios
    //     .get(`${API_SERVER}knowledgebase/api/courses/${courseId}/`, http.tokenConfig())
    //     .then((res) => {
    //         dispatch({ type: SET_COURSE_DETAIL, payload: res.data });
    //         const sectionItems = [];
    //         res.data.sections?.map((section) => {
    //             sectionItems.push(...section.section_items);
    //         });
    //         dispatch({ type: SET_COURSE_DETAIL_ITEMS, payload: sectionItems });
    //         const progressPercentage = getCourseProgressionPercentage(sectionItems);
    //         dispatch({ type: SET_COURSE_PROGRESS, payload: progressPercentage });
    //         console.log('res data >>>', res.data);
    //         courseDetail = res.data;
    //         return res.data;
    //     })
    //     .catch((err) => {
    //         toast.error('An error occurred in fetching courses, please try again' + JSON.stringify(err.response?.data));
    //         console.error(err.response);
    //         return false;
    //     });

    // console.log('course detail >>>', courseDetail);
    // return courseDetail;
};

const getArticleVersionDetail = async (id) => {
    try {
        const res = await axios.get(`${API_SERVER}knowledgebase/api/version-history/${id}/`, http.tokenConfig());
        return res.data;
    } catch (err) {
        console.error('An error occurred while fetching the article >>>', err, err.response);
        return false; // Or you can throw the error here or return an object with some default values
    }
};

const getEmployeeArticleReadActivities = async (articleId) => {
    try {
        const res = await axios.get(`${API_SERVER}knowledgebase/api/employee-article-read-activity/${articleId}/`, http.tokenConfig());
        return res.data;
    } catch (err) {
        console.error('An error occurred while fetching the article >>>', err, err.response);
        return false; // Or you can throw the error here or return an object with some default values
    }
};

const reAssignKBArticle = async (articleId, data) => {
    try {
        const res = await axios.put(`${API_SERVER}knowledgebase/api/reassign-article/${articleId}/`, data, http.tokenConfig());
        toast.success('user assigned sucessfully');
        return res.data;
    } catch (err) {
        console.error('An error occurred while fetching the article >>>', err, err.response);
        return false; // Or you can throw the error here or return an object with some default values
    }
};

const getAutoAIKBArticles = async () => {
    try {
        const { data } = await http.get(`${API_SERVER}knowledgebase/api/auto-kb-articles/`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log('error fetching kb ai articles >>', error);
        return false;
    }
};

const generateS3PresignedUrl = async (data) => {
    try {
        const res = await axios.post(`${API_SERVER}knowledgebase/api/generate-s3-presigned-url/`, data, http.tokenConfig());
        return res.data;
    } catch (err) {
        showAxiosErrorNotification(err?.response);
        console.error('An error occurred while creating the article >>>', err, err.response);
        return false;
    }
};

const getUserKBSummary = async (userId) => {
    try {
        const res = await axios.get(`${API_SERVER}knowledgebase/api/user-kb-summary/${userId}/`, http.tokenConfig());
        return res.data;
    } catch (err) {
        console.error('An error occurred while fetching kb summary >>>', err, err.response);
        return false;
    }
};

const getUserReadArticles = async (userId, queryDict) => {
    const query = queryDict ? serializeQuery(queryDict) : '';
    try {
        const res = await axios.get(`${API_SERVER}knowledgebase/api/user-read-articles/${userId}/?${query}`, http.tokenConfig());

        return res.data;
    } catch (err) {
        console.error('error in fetching user read articles', err, err.response);
        return false;
    }
};

const getUserAssignedArticles = async (userId, queryDict) => {
    const query = queryDict ? serializeQuery(queryDict) : '';
    try {
        const res = await axios.get(`${API_SERVER}knowledgebase/api/user-assigned-articles/${userId}/?${query}`, http.tokenConfig());

        return res.data;
    } catch (err) {
        console.error('error in fetching user assigned articles', err, err.response);
        return false;
    }
};


const getPositionArticles = async (positionId, queryDict) => {
    const query = queryDict ? serializeQuery(queryDict) : '';
    try {
        const res = await axios.get(`${API_SERVER}knowledgebase/api/position-courses-articles/${positionId}/?${query}`, http.tokenConfig());

        return res.data;
    } catch (err) {
        console.error('error in fetching position articles', err, err.response);
        return false;
    }
};

const getUserAssignedCourses = async (userId, queryDict) => {
    const query = queryDict ? serializeQuery(queryDict) : '';
    try {
        const res = await axios.get(`${API_SERVER}knowledgebase/api/user-assigned-courses/${userId}/?${query}`, http.tokenConfig());

        return res.data;
    } catch (err) {
        console.error('error in fetching user assigned courses', err, err.response);
        return false;
    }
};

const getUserReadCourses = async (userId, queryDict) => {
    const query = queryDict ? serializeQuery(queryDict) : '';
    try {
        const res = await axios.get(`${API_SERVER}knowledgebase/api/user-read-courses/${userId}/?${query}`, http.tokenConfig());

        return res.data;
    } catch (err) {
        console.error('error in fetching user read courses', err, err.response);
        return false;
    }
};

const postDefinition = async (data) => {
    const formData = new FormData();
    buildFormData(formData, data);

    try {
        const res = await axios.post(`${API_SERVER}knowledgebase/api/definitions-create/`, formData, http.multiPartTokenConfig());
        toast.success('Definition Created Successfully!');
        return res.data;
    } catch (err) {
        toast.error('Article Error ' + JSON.stringify(err.response.data));
        console.log('An error occurred while creating the article >>>', err, err.response);
        return false; // Or you can throw the error here or return an object with some default values
    }
};

const patchDefinition = async (id, data) => {
    try {
        const res = await axios.patch(`${API_SERVER}knowledgebase/api/definitions-detail/${id}/`, data, http.tokenConfig());
        toast.success('Category Updated');
        return res.data;
    } catch (err) {
        toast.error('An error occurred while updating Category ' + JSON.stringify(err.response.data));
        console.error('An error occurred while fetching ArticleCategories >>>', err, err.response);
        return []; // Or you can throw the error here or return an object with some default values
    }
};

const deleteDefinition = async (id) => {
    try {
        const res = await axios.delete(`${API_SERVER}knowledgebase/api/definitions-detail/${id}/`, http.tokenConfig());
        toast.success('Definition Deleted Successfully!');
        return res.data;
    } catch (err) {
        toast.error('An error occurred while deleting Definition ' + JSON.stringify(err.response.data));
        console.error('An error occurred while deleting Definition >>>', err, err.response);
        return []; // Or you can throw the error here or return an object with some default values
    }
};

export const getKBDefinitions = async (query) => {
    // pass query as a dict e.g. {simple_details: true}
    const queryParams = query ? serializeQuery(query) : '';
    try {
        const { data } = await http.get(`${API_SERVER}knowledgebase/api/definitions-create/?${queryParams}`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log('error fetching kb articles >>', error);
        return false;
    }
};

const getDefinitionCategories = async (query) => {
    // pass query as a dict e.g. {simple_details: true}
    const queryParams = query ? serializeQuery(query) : '';
    try {
        const res = await axios.get(`${API_SERVER}knowledgebase/api/definition-category/?${queryParams}`, http.tokenConfig());
        return res.data;
    } catch (err) {
        console.error('An error occurred while fetching ArticleCategories >>>', err, err.response);
        return []; // Or you can throw the error here or return an object with some default values
    }
};

const postDefinitionCategories = async (data) => {
    try {
        const res = await axios.post(`${API_SERVER}knowledgebase/api/definition-category/`, data, http.tokenConfig());
        toast.success('Category Added');
        return res.data;
    } catch (err) {
        toast.error('An error occurred while creating Category ' + JSON.stringify(err.response.data));
        console.error('An error occurred while fetching ArticleCategories >>>', err, err.response);
        return []; // Or you can throw the error here or return an object with some default values
    }
};

const patchDefinitionCategories = async (id, data) => {
    try {
        const res = await axios.patch(`${API_SERVER}knowledgebase/api/definition-category/${id}/`, data, http.tokenConfig());
        toast.success('Category Updated');
        return res.data;
    } catch (err) {
        toast.error('An error occurred while updating Category ' + JSON.stringify(err.response.data));
        console.error('An error occurred while fetching ArticleCategories >>>', err, err.response);
        return []; // Or you can throw the error here or return an object with some default values
    }
};

const deleteDefinitionCategories = async (id) => {
    try {
        const res = await axios.delete(`${API_SERVER}knowledgebase/api/definition-category/${id}/`, http.tokenConfig());
        toast.success('Category Deleted Successfully!');
        return res.data;
    } catch (err) {
        toast.error('An error occurred while deleting Category ' + JSON.stringify(err.response.data));
        console.error('An error occurred while deleting ArticleCategories >>>', err, err.response);
        return []; // Or you can throw the error here or return an object with some default values
    }
};

export const getPublicKBArticles = async (companySlug, query) => {
    // pass query as a dict e.g. {simple_details: true}
    const queryParams = query ? serializeQuery(query) : '';
    try {
        const { data } = await http.get(
            `${API_SERVER}knowledgebase/api/public-articles/${companySlug}/?${queryParams}`,
            http.tokenConfig()
        );
        return data;
    } catch (error) {
        console.log('error fetching kb articles >>', error);
        return false;
    }
};

export const getCategoriesWithPublicArticles = async (companySlug, query) => {
    // pass query as a dict e.g. {simple_details: true}
    const queryParams = query ? serializeQuery(query) : '';
    try {
        const { data } = await http.get(
            `${API_SERVER}knowledgebase/api/categories-with-public-articles/${companySlug}/?${queryParams}`,
            http.tokenConfig()
        );
        return data;
    } catch (error) {
        console.log('error fetching kb articles >>', error);
        return false;
    }
};

const fetchPublicArticle = async (id, returnError, query) => {
    const queryParams = query ? serializeQuery(query) : '';
    try {
        const res = await axios.get(`${API_SERVER}knowledgebase/api/public-articles/details/${id}/?${queryParams}`, http.tokenConfig());
        console.log('Data fetched successfully', { data: res.data });
        return res.data;
    } catch (err) {
        console.error('An error occurred while fetching the article >>>', err, err.response);
        return returnError ? err : false; // Or you can throw the error here or return an object with some default values
    }
};

export const getPublicKnowledgebases = async () => {
    try {
        const { data } = await http.get(`${API_SERVER}knowledgebase/api/public-knowledgebases/`, http.tokenConfig());
        return data;
    } catch (error) {
        return false;
    }
};

export const createPublicKnowledgebase = async (data) => {
    try {
        const res = await http.post(`${API_SERVER}knowledgebase/api/public-knowledgebases/`, data, http.tokenConfig());
        toast.success('Public Knowledgebase created successfully!');
        return res.data;
    } catch (err) {
        showAxiosErrorNotification(err?.response);
        return false;
    }
};

export const updatePublicKnowledgebase = async (id, data) => {
    try {
        const res = await http.patch(`${API_SERVER}knowledgebase/api/public-knowledgebases/${id}/`, data, http.tokenConfig());
        toast.success('Public Knowledgebase updated successfully!');
        return res.data;
    } catch (err) {
        console.log('ERROR UPDATING ;:::::', err?.response);
        showAxiosErrorNotification(err?.response);
        return false;
    }
};

export const deletePublicKnowledgebase = async (id) => {
    try {
        await http.delete(`${API_SERVER}knowledgebase/api/public-knowledgebases/${id}/`, http.tokenConfig());
        toast.success('Public Knowledgebase Deleted Successfully!');
        return 'success';
    } catch (err) {
        toast.error('An error occurred while deleting Public Knowledgebase ' + JSON.stringify(err.response.data));
        console.error('An error occurred while deleting Public Knowledgebase >>>', err, err.response);
        return false; // Or you can throw the error here or return an object with some default values
    }
};

export const getPublicKnowledgebaseDetails = async (id) => {
    try {
        const { data } = await http.get(`${API_SERVER}knowledgebase/api/public-knowledgebases/${id}/`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error, error.response);
        return false;
    }
};

export const validatePublicKnowledgebaseDomain = async (domain) => {
    try {
        const { data } = await http.post(
            `${API_SERVER}knowledgebase/api/public-knowledgebase-validate-domain/`,
            { domain: domain },
            http.tokenConfig()
        );
        toast.success('Domain verified successfully!');
        return data;
    } catch (error) {
        console.log(error, error.response);
        toast.error("Could not verify domain. Please ensure you've followed the verification steps correctly");
        return false;
    }
};

const getCoursesAndArticlesToRead = async (query) => {
    const queryParams = query ? serializeQuery(query) : '';
    try {
        const { data } = await http.get(`${API_SERVER}knowledgebase/api/courses-articles-to-read/?${queryParams}`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error, error.response);
        return false;
    }
};

const getQuizzes = async (query) => {
    const queryParams = query ? serializeQuery(query) : '';
    try {
        const { data } = await http.get(`${API_SERVER}knowledgebase/api/quizzes/?${queryParams}`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error, error.response);
        return false;
    }
};

const getQuizDetails = async (quizId) => {
    return await axios.get(`${API_SERVER}knowledgebase/api/quizzes/${quizId}/`, http.tokenConfig());
};

const createQuiz = async (data) => {
    try {
        const res = await axios.post(`${API_SERVER}knowledgebase/api/quiz-create/`, data, http.tokenConfig());
        toast.success('Quiz created successfully!');
        return res.data;
    } catch (err) {
        // toast.error('Course Create Error ' + JSON.stringify(err.response.data));
        showAxiosErrorNotification(err?.response);
        return false;
    }
};

const updateQuiz = async (id, data) => {
    try {
        const response = await axios.patch(`${API_SERVER}knowledgebase/api/quizzes/${id}/`, data, http.tokenConfig());
        toast.success('Quiz updated successfully!');
        return response.data;
    } catch (error) {
        showAxiosErrorNotification(error?.response);
        return false;
    }
};

const deleteQuiz = async (id) => {
    try {
        await axios.delete(`${API_SERVER}knowledgebase/api/quizzes/${id}/`, http.tokenConfig());
        toast.success('Quiz Deleted Successfully!');
        return 'success';
    } catch (err) {
        toast.error('An error occurred while deleting Quiz ' + JSON.stringify(err.response.data));
        console.error('An error occurred while deleting Quiz >>>', err, err.response);
        return false; // Or you can throw the error here or return an object with some default values
    }
};

const quizResponseBulkCreate = async (data) => {
    try {
        const res = await axios.post(`${API_SERVER}knowledgebase/api/quiz-responses-bulk-create/`, data, http.tokenConfig());
        // toast.success('Quiz Responses created successfully!');
        return res.data;
    } catch (err) {
        showAxiosErrorNotification(err?.response);
        return false;
    }
};

const getQuizResponses = async (filterData) => {
    const staffId = filterData.staffId;
    const quizId = filterData.quizId;
    try {
        const { data } = await http.get(`${API_SERVER}knowledgebase/api/quiz-responses/${staffId}/${quizId}`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error, error.response);
        return false;
    }
};

const aiPoweredKBSearch = async (query) => {
    const queryParams = query ? serializeQuery(query) : '';
    const timeout = 50000; // 50 seconds timeout

    const fetchData = async () => {
        try {
            const { data } = await http.get(`${API_SERVER}company/api/ai-powered-kb-search/?${queryParams}`, http.tokenConfig());
            return data;
        } catch (error) {
            console.log(error, error.response);
            return { ai_answer: 'An error occurred while fetching data. Please try again later.' };
        }
    };

    const timeoutPromise = new Promise((_, reject) =>
        setTimeout(() => reject(new Error('Request took too long please try again later')), timeout)
    );

    try {
        return await Promise.race([fetchData(), timeoutPromise]);
    } catch (error) {
        return { ai_answer: error.message };
    }
};

const getKBLeaderboard = async () => {
    try {
        const { data } = await http.get(`${API_SERVER}knowledgebase/api/kb-leaderboard/`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error, error.response);
        return false;
    }
};

const bulkDeleteArticles = async (articleIds) => {
    try {
        const res = await axios.post(`${API_SERVER}knowledgebase/api/articles/bulk-delete/`, { ids: articleIds }, http.tokenConfig());
        toast.success('Articles deleted successfully!');
        return res.data;
    } catch (err) {
        showAxiosErrorNotification(err?.response);
        return false;
    }
};

const bulkUpdateArticles = async (articleIds, data) => {
    try {
        const res = await axios.patch(
            `${API_SERVER}knowledgebase/api/articles/bulk-update/`,
            { ids: articleIds, ...data },
            http.tokenConfig()
        );
        toast.success('Articles updated successfully!');
        return res.data;
    } catch (err) {
        showAxiosErrorNotification(err?.response);
        return false;
    }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    aiPoweredKBSearch,
    createArticleType,
    updateArticleType,
    deleteArticleType,
    getArticleTypes,
    getAssignedCourses,
    createCourse,
    patchCourse,
    getCourseCategories,
    addCourseCategory,
    updateCourseCategory,
    getArticleCategories,
    getArticle,
    deleteArticleCategories,
    patchArticleCategories,
    postArticleCategories,
    patchArticle,
    fetchArticle,
    getCategoriesWithArticles,
    postArticle,
    postArticleVideo,
    getKBArticles,
    getAcknowledgements,
    createKBAcknowledgement,
    acknowledgeArticle,
    fetchCourseDetails,
    postQuestionArticle,
    markCourseAsReadForEmployee,
    getArticleVersionDetail,
    getEmployeeArticleReadActivities,
    reAssignKBArticle,
    getAutoAIKBArticles,
    reassignCourse,
    getCourseAssignedUsers,
    deleteArticle,
    generateS3PresignedUrl,
    getUserKBSummary,
    getUserReadArticles,
    getUserAssignedArticles,
    getPositionArticles,
    getUserAssignedCourses,
    getUserReadCourses,
    getDraftArticles,
    createDraftArticle,
    updateDraftArticle,
    deleteDraftArticle,
    getDraftArticleDetails,
    getKBDefinitions,
    postDefinition,
    getDefinitionCategories,
    patchDefinitionCategories,
    postDefinitionCategories,
    deleteDefinitionCategories,
    patchDefinition,
    getPublicKBArticles,
    getCategoriesWithPublicArticles,
    fetchPublicArticle,
    getPublicKnowledgebases,
    createPublicKnowledgebase,
    updatePublicKnowledgebase,
    deletePublicKnowledgebase,
    getPublicKnowledgebaseDetails,
    validatePublicKnowledgebaseDomain,
    getCoursesAndArticlesToRead,
    deleteDefinition,
    getQuizzes,
    getQuizDetails,
    createQuiz,
    updateQuiz,
    deleteQuiz,
    quizResponseBulkCreate,
    getQuizResponses,
    getKBArticlesSimple,
    getKBLeaderboard,
    bulkDeleteArticles,
    bulkUpdateArticles
};
